#home {
    height: 100%;
    background-color: #011930;
    -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    display: flex;
    flex-direction: column;
}

.header-text-container{
    height: 100%;
    margin: 20% 10% 20% 10%;
}

.header-logo-container{
    display: flex;
    justify-content: center;
}
.header-logo{
    content:url(../img/logo.png);
    width: 10%;
    flex: 0 1 500px;
}