#rent {
    background-color: #011930;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.rent-card-container{
    display:flex;
    flex-direction: column;
    flex: 1 1 400px;
    margin: 2%;    
    background-color: #031426;
    align-items: center;
}
.rent-card-container.max {
    height: max-content;
}
.rent-card-logo-container{
    display: flex;
    height: 80px;
    margin: 20px;
}
.rent-card-contents{
    font-size: medium;
    color: rgb(180, 180, 180);
    line-height: 2em;
    padding: 8%;
}

[class*="rent-logo"]{
    width: 200px;
    object-fit: contain;
}

.rent-logo1{
    content:url(../img/logo1.png);
}
.rent-logo2{
    content:url(../img/logo2.png);
}
.rent-logo3{
    content:url(../img/logo3.png);
}
.rent-logo4{
    content:url(../img/logo4.png);
}
